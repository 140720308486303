<div class="header">
  <div class="header-info flex items-center">
    <ie-breadcrumbs></ie-breadcrumbs>
  </div>
  <div class="header-menu flex">
    <ie-button
      class="notifications-button mr-8"
      nzPopoverOverlayClassName="no-paddings"
      nzPopoverTrigger="click"
      nz-popover
      [buttonType]="notificationPopover ? 'primary' : 'outline'"
      [onlyIcon]="true"
      [size]="'sm'"
      [tabindex]="5"
      (click)="showNotificationPopover()"
      (keydown)="showNotificationPopover()"
      (nzPopoverVisibleChange)="notificationPopover = $event"
      [nzPopoverVisible]="notificationPopover"
      [nzPopoverContent]="contentTemplate"
      [nzPopoverPlacement]="['bottom', 'bottomRight']"
    >
      <ie-icons icon="notification" [width]="20" [height]="20"></ie-icons>
      @if (notificationBadges) {
        <div class="notification-badge"></div>
      }
    </ie-button>

    <ie-button
      class="mr-8"
      [buttonType]="theme === ThemeMode.DARK ? 'primary' : 'outline'"
      [onlyIcon]="true"
      [size]="'sm'"
      (click)="themeModeToggle()"
    >
      <ie-icons icon="moon" [width]="20" [height]="20"></ie-icons>
    </ie-button>

    <ie-button
      *ngIf="$user | async; let user"
      buttonType="outline"
      nz-button
      nz-dropdown
      [size]="'sm'"
      [nzTrigger]="'click'"
      [nzDropdownMenu]="menu"
      [nzPlacement]="'bottomRight'"
    >
      <div class="profile-button">
        <nz-avatar
          nzSize="small"
          class="profile-menu-icon"
          nzIcon="user"
          [nzText]="user ? user.firstName.charAt(0) : 'N'"
        ></nz-avatar>
        {{ user?.firstName }}
        <ie-icons
          icon="arrow-down-s-line"
          [width]="20"
          [height]="20"
        ></ie-icons>
      </div>
    </ie-button>

    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu>
        <li
          nz-menu-item
          [tabindex]="100"
          (click)="logout()"
          (keydown)="logout()"
        >
          {{ 'LOGOUT' | translate }}
        </li>
      </ul>
    </nz-dropdown-menu>
  </div>
</div>

<ng-template #contentTemplate>
  <div class="notifications">
    <app-notifications
      [notifications]="notifications"
      [loading]="(getNotificationsReq.isLoading | async) || false"
      (seeAll)="seeAll()"
      (statusChanged)="getNotifications($event)"
    ></app-notifications>
  </div>
</ng-template>

<nz-modal
  [(nzVisible)]="notificationModal"
  [nzFooter]="null"
  [nzClosable]="true"
  (nzOnCancel)="notificationModal = false"
  nzClassName="no-paddings"
  nzTitle="Notification"
  nzWidth="600px"
>
  <div *nzModalContent>
    <app-notifications
      mode="seeAll"
      [notifications]="notifications"
      [loading]="(getNotificationsReq.isLoading | async) || false"
      (seeAll)="seeAll()"
      (statusChanged)="getNotifications($event)"
    ></app-notifications>
  </div>
</nz-modal>
