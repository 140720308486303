import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '@ieCore/services/auth.service';
import { NavService } from '@ieCore/services/nav.service';

export const guestGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const navService = inject(NavService);
  const router = inject(Router);

  if (authService.isAuthenticated()) {
    const userType = authService.getUserTypeFromToken();
    const route = navService.getRouteByUserType(userType);

    router
      .navigate([route])
      .then(() => console.log(`Navigated to ${userType} dashboard page`));

    return false;
  }

  return true;
};
