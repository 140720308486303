<div class="header">
  <div class="header-info flex items-center">
    <ie-breadcrumbs></ie-breadcrumbs>
  </div>
  <div class="header-menu flex">
    <ie-button
      class="notifications-button mr-8"
      nzPopoverOverlayClassName="no-paddings"
      nzPopoverTrigger="click"
      size="sm"
      nz-popover
      [buttonType]="notificationPopover ? 'primary' : 'outline'"
      [onlyIcon]="true"
      [tabindex]="5"
      (click)="showNotificationPopover()"
      (keydown)="showNotificationPopover()"
      (nzPopoverVisibleChange)="notificationPopover = $event"
      [nzPopoverVisible]="notificationPopover"
      [nzPopoverContent]="contentTemplate"
      [nzPopoverPlacement]="['bottom', 'bottomRight']"
    >
      <ie-icons icon="notification" [width]="20" [height]="20"></ie-icons>
      @if (notificationBadges) {
        <div class="notification-badge"></div>
      }
    </ie-button>

    <ie-button
      *ngIf="$user | async; let user"
      buttonType="outline"
      nz-button
      nz-dropdown
      size="sm"
      nzTrigger="click"
      (nzVisibleChange)="userDropdownStateChanged($event)"
      [nzDropdownMenu]="menu"
      [nzPlacement]="'bottomRight'"
    >
      <div class="profile-button">
        <nz-avatar
          nzSize="small"
          class="profile-menu-icon"
          [nzText]="user ? user.firstName.charAt(0) : 'N'"
        ></nz-avatar>
        {{ user?.firstName }}
        <ie-icons
          icon="arrow-down-s-line"
          [width]="20"
          [height]="20"
        ></ie-icons>
      </div>
    </ie-button>

    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul class="user-menu" nz-menu *ngIf="$user | async; let user">
        <li class="bottom-divider m-12 pb-8">
          <div class="flex">
            @if (showUserDropDown) {
              <nz-avatar
                class="profile-menu-icon mr-8"
                [nzSize]="30"
                [nzText]="user ? user.firstName.charAt(0) : 'N'"
              ></nz-avatar>
            }
            <div class="flex flex-col">
              <span class="label-l"
                >{{ user?.firstName }} {{ user?.lastName }}</span
              >
              <span class="body-c">{{ user?.role }}</span>
            </div>
          </div>
        </li>
        <li class="bottom-divider mt-12 ml-12 mr-12 pb-8">
          <div class="flex flex-col">
            <span class="label-s fw-500">{{
              'HEADER.RELEASE_NOTES' | translate
            }}</span>
            <span class="body-c">{{ appVersion }}</span>
          </div>
        </li>
        <li>
          <ie-button
            buttonType="link"
            [tabindex]="100"
            (click)="logout()"
            (keydown)="logout()"
            class="flex justify-center items-center"
          >
            {{ 'LOGOUT' | translate }}
          </ie-button>
        </li>
      </ul>
    </nz-dropdown-menu>
  </div>
</div>

<ng-template #contentTemplate>
  <div class="notifications">
    <app-notifications
      [notifications]="notifications"
      [allNotificationsCount]="this.notificationCount"
      [loading]="(getNotificationsReq.isLoading | async) || false"
      (seeAll)="seeAll()"
      (statusChanged)="getNotifications($event)"
      (closeModal)="notificationPopover = false"
      (markAsRead)="markAsRead($event)"
    ></app-notifications>
  </div>
</ng-template>

<nz-modal
  nzClassName="no-paddings notification-modal"
  nzWidth="600px"
  [(nzVisible)]="notificationModal"
  [nzFooter]="null"
  [nzClosable]="true"
  (nzOnCancel)="notificationModal = false"
  [nzTitle]="'NOTIFICATION.TITLE' | translate"
>
  <div *nzModalContent>
    <app-notifications
      [mode]="NotificationComponentTypes.SEE_ALL"
      [notifications]="notifications"
      [allNotificationsCount]="this.notificationCount"
      (seeAll)="seeAll()"
      (statusChanged)="getNotifications($event)"
      (loadMore)="loadMoreNotifications()"
      (markAsRead)="markAsRead($event)"
    ></app-notifications>
  </div>
</nz-modal>
