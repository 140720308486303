<div class="menu-items" [ngClass]="{ small: small }">
  @for (item of filteredItems; track $index) {
    <button
      class="menu-item body-l cl-on-surface"
      [ngClass]="{
        'mt-8 mb-8': small,
        popover: small && item.children && item.children.length > 0,
      }"
      [routerLink]="item.route !== undefined ? [item.route | navigate] : null"
      [routerLinkActiveOptions]="{ exact: item.exact ? item.exact : false }"
      (click)="toggleMenu(item)"
      routerLinkActive="active"
      *appHasPermission="item.permission || ''"
    >
      @if (small && item.children && item.children.length > 0) {
        <ie-icons
          nz-popover
          [nzPopoverContent]="contentTemplate"
          nzPopoverPlacement="right"
          [(nzPopoverVisible)]="item.visible"
          [width]="20"
          [height]="20"
          [icon]="item.icon!"
          [ngClass]="{ 'mr-16': !small }"
        ></ie-icons>

        <ng-template #contentTemplate>
          <div class="flex flex-col">
            @for (child of item.children; track $index) {
              <a
                class="p-4 cl-on-background"
                (click)="item.visible = false"
                [routerLink]="[child.route! | navigate]"
              >
                {{ child.label | translate }}
              </a>
            }
          </div>
        </ng-template>
      } @else {
        <ie-icons
          [width]="20"
          [height]="20"
          [icon]="item.icon!"
          [ngClass]="{ 'mr-16': !small }"
        ></ie-icons>
      }

      @if (!small) {
        <span class="ellipsis">{{ item.label | translate }}</span>

        @if (item.children && item.children.length > 0) {
          <ie-icons
            class="collapse-icon"
            [width]="20"
            [height]="20"
            [icon]="'arrow-down-s-line'"
            [ngClass]="{ 'ml-auto': !small, collapsed: item.opened }"
          ></ie-icons>
        }
      }
    </button>
    @if (item.children?.length) {
      <div
        class="flex flex-col menu-children"
        [ngClass]="{ collapsed: item.opened }"
      >
        @for (child of item.children; track $index) {
          <div
            class="menu-item-child body-l cl-on-surface"
            [ngClass]="{ 'mt-8 mb-8': small }"
            [routerLink]="[child.route! | navigate]"
            [routerLinkActiveOptions]="{ exact: child.exact }"
            routerLinkActive="active"
            *appHasPermission="child.permission || ''"
          >
            {{ child.label | translate }}
          </div>
        }
      </div>
    }
  }
</div>
