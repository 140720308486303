import { Injectable } from '@angular/core';
import { STORAGE_KEY } from '@ieCore/consts/layout.consts';
import { LayoutState } from '@ieCore/enums/layout';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  constructor() {}

  setMenuState(state: LayoutState) {
    localStorage.setItem(STORAGE_KEY, state);
  }

  getMenuState(): LayoutState {
    switch (localStorage.getItem(STORAGE_KEY)) {
      case LayoutState.OPENED:
        return LayoutState.OPENED;
      case LayoutState.CLOSED:
        return LayoutState.CLOSED;
      default:
        return LayoutState.OPENED;
    }
  }
}
