import { Component, OnInit } from '@angular/core';
import { NavService } from '@ieCore/services/nav.service';
import { IeIconsComponent } from '@ieShared/components/ie-icons/ie-icons.component';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NzInputDirective, NzInputGroupComponent } from 'ng-zorro-antd/input';
import { NzAvatarComponent, NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { TranslateModule } from '@ngx-translate/core';
import { requestLoading } from '@ieCore/helpers/request-loading.helper';
import { AuthService } from '@ieCore/services/auth.service';
import { IeCardComponent } from '@ieShared/components/ie-card/ie-card.component';
import { IeButtonComponent } from '@ieShared/components/ie-button/ie-button.component';
import {
  NzColDirective,
  NzGridModule,
  NzRowDirective,
} from 'ng-zorro-antd/grid';
import { NzBadgeModule } from 'ng-zorro-antd/badge';

import { PaginatedResponseDto } from 'shared-general-libs/dto/pagination';
import { NotificationResponseDto } from 'shared-general-libs/dto/notification';
import { NotificationService } from '@ieRoot/app/core/services/notification.service';
import { TimeagoModule } from 'ngx-timeago';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NotificationRequestParams } from '@ieRoot/app/shared/interfaces/notifications.interface';
import { IeAlertComponent } from '@ieShared/components/ie-alert/ie-alert.component';
import { IeBreadcrumbsComponent } from '@ieShared/components/ie-breadcrumbs/ie-breadcrumbs.component';
import { Store } from '@ngrx/store';
import { selectUser } from '@ieApp/store/user/user.selectors';
import { ThemeService } from '@ieCore/services/theme.service';
import { ThemeMode } from '@ieCore/enums/theme';
import { InAppStatus } from 'shared-general-libs/type/notification';
import { NotificationsComponent } from '@ieShared/components/notifications/notifications.component';
import { NzModalModule } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    IeIconsComponent,
    NgOptimizedImage,
    NzInputDirective,
    NzInputGroupComponent,
    NzAvatarComponent,
    TranslateModule,
    NzPopoverModule,
    IeCardComponent,
    IeButtonComponent,
    NzColDirective,
    NzRowDirective,
    NzBadgeModule,
    NzAvatarModule,
    TimeagoModule,
    CommonModule,
    NzGridModule,
    NzDropDownModule,
    IeAlertComponent,
    IeBreadcrumbsComponent,
    NotificationsComponent,
    NzModalModule,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
  logoutRequest = requestLoading();
  getNotificationsReq =
    requestLoading<PaginatedResponseDto<NotificationResponseDto>>();

  notifications: NotificationResponseDto[] = [];
  notificationBadges = 0;
  notificationPopover = false;
  notificationModal = false;

  tableParams: NotificationRequestParams = {
    page: 1,
    itemsPerPage: 10,
  };

  theme = this.themeService.getCurrentTheme();
  ThemeMode = ThemeMode;

  $user = this.store.select(selectUser);

  constructor(
    private readonly store: Store,
    private authService: AuthService,
    private notificationService: NotificationService,
    public navService: NavService,
    private themeService: ThemeService,
  ) {}

  ngOnInit(): void {
    this.getNotifications();
  }

  logout(): void {
    const authData = this.authService.getAuthData();
    const payload = {
      refreshToken: authData.refreshToken,
    };
    this.logoutRequest = this.authService.logout(payload);
    this.logoutRequest.response.subscribe({
      next: () => {},
      error: () => {},
    });
  }

  getNotifications(status = 'all') {
    const params = structuredClone(this.tableParams);

    if (status === 'unread') {
      params.inappStatus = 'UNREAD';
    }

    this.getNotificationsReq =
      this.notificationService.getNotifications(params);

    this.getNotificationsReq.response.subscribe((data) => {
      this.notifications = data.items;
      this.notificationBadges = data.items.reduce(
        (acc, item) => acc + (item.inappStatus === InAppStatus.UNREAD ? 1 : 0),
        0,
      );
    });
  }

  showNotificationPopover() {
    this.notificationPopover = true;
  }

  seeAll() {
    this.notificationPopover = false;
    this.notificationModal = true;
  }

  themeModeToggle() {
    this.themeService.toggleTheme(
      this.theme === ThemeMode.DARK ? ThemeMode.LIGHT : ThemeMode.DARK,
    );
    this.theme = this.themeService.getCurrentTheme();
  }
}
