import { HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { AuthService } from '@ieCore/services/auth.service';
import { inject } from '@angular/core';
import { catchError, take, tap } from 'rxjs/operators';
import { filter, switchMap } from 'rxjs';
import {
  isIeCall,
  isRefreshTokenCall,
} from '@ieCore/helpers/interceptor.helper';

export const refreshTokenInterceptor: HttpInterceptorFn = (req, next) => {
  if (skipRefreshTokenInterceptor(req)) return next(req);

  const authService = inject(AuthService);

  if (!authService.isAuthenticated()) {
    return next(req);
  }

  if (!authService.tokenIsExpired()) {
    return next(req);
  }

  const authData = authService.getAuthData();

  if (authService.isNewTokenRequested()) {
    return authService.refreshTokenSubject.pipe(
      filter((status) => status === true),
      take(1),
      switchMap(() => next(req)),
    );
  }

  authService.setRefreshTokenRequested();
  return authService.refreshToken(authData.refreshToken).response.pipe(
    switchMap(() => next(req)),
    tap(() => authService.setNewTokenReady()),
    catchError(() => {
      return next(req);
    }),
  );
};

const skipRefreshTokenInterceptor = (req: HttpRequest<unknown>) => {
  return !isIeCall(req) || isRefreshTokenCall(req);
};
