import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemeMode } from '@ieCore/enums/theme';
import { IeButtonComponent } from '@ieShared/components/ie-button/ie-button.component';
import { NotificationResponseDto } from 'shared-general-libs/dto/notification';
import { JsonPipe, NgClass, NgOptimizedImage } from '@angular/common';
import { IeAlertComponent } from '@ieShared/components/ie-alert/ie-alert.component';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationComponent } from '@ieShared/pages/notification/notification.component';
import { NzAvatarComponent } from 'ng-zorro-antd/avatar';
import { TimeagoModule } from 'ngx-timeago';
import { InAppStatus } from 'shared-general-libs/type/notification';
import { NzSpinComponent } from 'ng-zorro-antd/spin';

@Component({
  selector: 'app-notifications',
  standalone: true,
  imports: [
    IeButtonComponent,
    JsonPipe,
    IeAlertComponent,
    TranslateModule,
    NgOptimizedImage,
    NotificationComponent,
    NzAvatarComponent,
    TimeagoModule,
    NgClass,
    NzSpinComponent,
  ],
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.scss',
})
export class NotificationsComponent {
  @Input() mode: 'simple' | 'seeAll' = 'simple';
  @Input() notifications: NotificationResponseDto[] = [];
  @Input() loading: boolean = false;
  @Output() statusChanged = new EventEmitter<'all' | 'unread'>();
  @Output() seeAll = new EventEmitter<void>();

  InAppStatus = InAppStatus;
  status: 'all' | 'unread' = 'all';

  protected readonly ThemeMode = ThemeMode;
  constructor() {}

  statusChange(status: 'all' | 'unread') {
    this.status = status;
    this.statusChanged.emit(status);
  }
}
