import { Injectable } from '@angular/core';
import * as UserActions from '@ieApp/store/user/user.actions';
import { filter, firstValueFrom } from 'rxjs';
import { selectUser, selectUserError } from '@ieApp/store/user/user.selectors';
import { take } from 'rxjs/operators';
import { AuthService } from '@ieCore/services/auth.service';
import { Store } from '@ngrx/store';
import { ThemeService } from '@ieCore/services/theme.service';

@Injectable({
  providedIn: 'root',
})
export class InitAppService {
  constructor(
    private authService: AuthService,
    private themeService: ThemeService,
    private store: Store,
  ) {}

  async initUserStore(): Promise<void> {
    const authData = this.authService.getAuthData();

    if (authData.accessToken) {
      this.store.dispatch(UserActions.load());

      await Promise.race([
        firstValueFrom(this.store.select(selectUserError).pipe(take(1))),
        firstValueFrom(
          this.store.select(selectUser).pipe(
            filter((userState) => userState !== null),
            take(1),
          ),
        ),
      ]);

      return Promise.resolve();
    }

    return Promise.resolve();
  }

  initTheme(): void {
    const theme = this.themeService.getCurrentTheme();
    if (theme) {
      this.themeService.toggleTheme(theme);
    }
  }
}

export function userInitFactory(initAppService: InitAppService) {
  return () => initAppService.initUserStore();
}

export function themeInitFactory(initAppService: InitAppService) {
  return () => initAppService.initTheme();
}
