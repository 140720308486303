import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, firstValueFrom, mergeMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class PageTitleService {
  constructor(
    private titleService: Title,
    private translate: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  init() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        mergeMap((route) => route.data),
      )
      .subscribe(async (event) => {
        let title = await firstValueFrom(
          this.translate.get('PAGE_TITLE.DEFAULT'),
        );

        if (event['title']) {
          title =
            (await firstValueFrom(this.translate.get(event['title']))) +
            ' | ' +
            title;
        }

        this.titleService.setTitle(title);
      });
  }
}
