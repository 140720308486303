import { UserType } from 'shared-general-libs/type/user';
import { RoutesConfig } from '@ieApp/routes.config';
import { NavItem } from '@ieShared/interfaces/nav-bar.interface';
import { Permission } from 'shared-general-libs/enum/permission';
// TODO add access by roles in menu config
const ENTERPRISE_MENU: NavItem[] = [
  // {
  //   label: 'NAV_MENU.DASHBOARD',
  //   icon: 'home-2',
  //   route: RoutesConfig.enterpriseDashboard,
  //   permission: [],
  //   exact: true,
  // },
  {
    label: 'NAV_MENU.MY_TASKS',
    icon: 'task',
    route: RoutesConfig.enterpriseMyTasks,
    permission: [],
    exact: false,
  },
  {
    label: 'NAV_MENU.ACCOUNTING',
    icon: 'activity',
    route: RoutesConfig.enterpriseAccounting,
    permission: [],
    exact: false,
  },
  // {
  //   label: 'NAV_MENU.PLAN',
  //   icon: 'gps',
  //   opened: false,
  //   permission: [],
  //   children: [
  //     {
  //       label: 'NAV_MENU.GOALS',
  //       route: RoutesConfig.enterpriseGoals,
  //       permission: [],
  //       exact: false,
  //     },
  //   ],
  // },
  {
    label: 'NAV_MENU.COLLECT',
    icon: '3square',
    route: RoutesConfig.enterpriseCollect,
    permission: [Permission.Collect.VIEW],
    exact: false,
  },
  {
    label: 'NAV_MENU.REVIEW',
    icon: 'review',
    route: RoutesConfig.enterpriseReview,
    permission: [],
    exact: false,
  },
  // {
  //   label: 'NAV_MENU.AUDIT',
  //   icon: 'medal-star',
  //   exact: false,
  //   opened: false,
  //   permission: [],
  //   children: [
  //     {
  //       label: 'NAV_MENU.APPLICATION',
  //       route: RoutesConfig.enterpriseApplication,
  //       permission: [],
  //       exact: true,
  //     },
  //     {
  //       label: 'NAV_MENU.CERTIFICATES',
  //       route: RoutesConfig.enterpriseCertificates,
  //       permission: [],
  //       exact: true,
  //     },
  //   ],
  // },
  // {
  //   label: 'NAV_MENU.TRACK',
  //   icon: 'routing',
  //   exact: false,
  //   opened: false,
  //   permission: [],
  //   children: [
  //     {
  //       label: 'NAV_MENU.WASTE',
  //       route: RoutesConfig.enterpriseWaste,
  //       permission: [],
  //       exact: true,
  //     },
  //     {
  //       label: 'NAV_MENU.PRODUCTS',
  //       route: RoutesConfig.enterpriseProducts,
  //       permission: [],
  //       exact: true,
  //     },
  //   ],
  // },
  // {
  //   label: 'NAV_MENU.NOTIFICATIONS',
  //   icon: 'notifications',
  //   route: RoutesConfig.enterpriseNotifications,
  //   permission: [],
  //   exact: true,
  // },
  {
    label: 'NAV_MENU.SETTINGS',
    icon: 'setting-2',
    route: RoutesConfig.enterpriseSettings,
    permission: [],
    exact: false,
  },
];

const PARTNER_MENU: NavItem[] = [
  // {
  //   label: 'NAV_MENU.DASHBOARD',
  //   icon: 'home',
  //   route: RoutesConfig.partnerDashboard,
  //   permission: [],
  //   exact: true,
  // },
  {
    label: 'NAV_MENU.DATA_REQUESTS',
    icon: 'home',
    route: RoutesConfig.partnerDataRequests,
    permission: [],
    exact: true,
  },
  {
    label: 'NAV_MENU.WASTE_DATA_REQUESTS',
    icon: 'home',
    route: RoutesConfig.partnerWasteDataRequests,
    permission: [],
    exact: true,
  },
  {
    label: 'NAV_MENU.SETTINGS',
    icon: 'setting-2',
    route: RoutesConfig.partnerSettings,
    permission: [],
    exact: false,
  },
];

const PORTFOLIO_USER: NavItem[] = [
  // {
  //   label: 'NAV_MENU.DASHBOARD',
  //   icon: 'home',
  //   route: RoutesConfig.portfolioDashboard,
  //   permission: [],
  //   exact: true,
  // },
  {
    label: 'NAV_MENU.DATA_REQUESTS',
    icon: 'task',
    route: RoutesConfig.portfolioDataRequests,
    permission: [],
    exact: true,
  },
];

const CERTIFIER_USER: NavItem[] = [
  // {
  //   label: 'NAV_MENU.DASHBOARD',
  //   icon: 'home',
  //   route: RoutesConfig.certifierDashboard,
  //   permission: [],
  //   exact: true,
  // },
  {
    label: 'NAV_MENU.APPLICATIONS',
    icon: 'home',
    permission: [],
    opened: false,
    children: [
      {
        label: 'NAV_MENU.ASSIGN',
        route: RoutesConfig.certifierAssign,
        permission: [],
        exact: false,
      },
      {
        label: 'NAV_MENU.VERIFY',
        route: RoutesConfig.certifierVerify,
        permission: [],
        exact: false,
      },
      {
        label: 'NAV_MENU.REVIEW',
        route: RoutesConfig.certifierReview,
        permission: [],
        exact: false,
      },
    ],
  },
  {
    label: 'NAV_MENU.CERTIFICATIONS',
    icon: 'home',
    route: RoutesConfig.certifierCertifications,
    permission: [],
    exact: true,
  },
  {
    label: 'NAV_MENU.AUDITS',
    icon: 'medal-star',
    route: RoutesConfig.certifierAudits,
    permission: [],
    exact: true,
  },
];

const AUDITOR_USER: NavItem[] = [
  // {
  //   label: 'NAV_MENU.DASHBOARD',
  //   icon: 'home',
  //   route: RoutesConfig.auditorDashboard,
  //   permission: [],
  //   exact: true,
  // },
  {
    label: 'NAV_MENU.APPLICATIONS',
    icon: 'home',
    opened: false,
    permission: [],
    children: [
      {
        label: 'NAV_MENU.ASSIGN',
        route: RoutesConfig.auditorAssign,
        permission: [],
        exact: false,
      },
      {
        label: 'NAV_MENU.VERIFY',
        route: RoutesConfig.auditorVerify,
        permission: [],
        exact: false,
      },
      {
        label: 'NAV_MENU.REVIEW',
        route: RoutesConfig.auditorReview,
        permission: [],
        exact: false,
      },
    ],
  },
  {
    label: 'NAV_MENU.CERTIFICATIONS',
    icon: 'home',
    route: RoutesConfig.auditorCertifications,
    permission: [],
    exact: true,
  },
  {
    label: 'NAV_MENU.AUDITS',
    icon: 'medal-star',
    route: RoutesConfig.auditorAudits,
    permission: [],
    exact: true,
  },
];

const SUPER_ADMIN: NavItem[] = [
  // {
  //   label: 'NAV_MENU.DASHBOARD',
  //   icon: 'home',
  //   route: RoutesConfig.adminDashboard,
  //   permission: [],
  //   exact: true,
  // },
  {
    label: 'NAV_MENU.APPLICATIONS',
    icon: 'task',
    permission: [],
    children: [
      {
        label: 'NAV_MENU.ASSIGN',
        route: RoutesConfig.adminAssign,
        permission: [],
        exact: false,
      },
      {
        label: 'NAV_MENU.VERIFY',
        route: RoutesConfig.adminVerify,
        permission: [],
        exact: false,
      },
      {
        label: 'NAV_MENU.REVIEW',
        route: RoutesConfig.adminReview,
        permission: [],
        exact: false,
      },
    ],
  },
  {
    label: 'NAV_MENU.CERTIFICATIONS',
    icon: 'task',
    route: RoutesConfig.adminCertifications,
    permission: [],
    exact: true,
  },
  {
    label: 'NAV_MENU.AUDITS',
    icon: 'medal-star',
    route: RoutesConfig.adminAudits,
    permission: [],
    exact: true,
  },
];

export const NAV_MENU = {
  [UserType.ENTERPRISE_USER]: ENTERPRISE_MENU,
  [UserType.ENTERPRISE_ADMIN]: ENTERPRISE_MENU,
  [UserType.PARTNER_USER]: PARTNER_MENU,
  [UserType.PORTFOLIO_USER]: PORTFOLIO_USER,
  [UserType.CERTIFIER_USER]: CERTIFIER_USER,
  [UserType.AUDITOR_USER]: AUDITOR_USER,
  [UserType.SUPER_ADMIN]: SUPER_ADMIN,
};
