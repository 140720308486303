import { Injectable } from '@angular/core';
import { ThemeMode } from '@ieCore/enums/theme';
import { THEME_KEY } from '@ieCore/consts/layout.consts';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  toggleTheme(mode: ThemeMode) {
    localStorage.setItem(THEME_KEY, mode);

    document.documentElement.classList.remove(
      mode === ThemeMode.DARK ? ThemeMode.LIGHT : ThemeMode.DARK,
    );
    document.documentElement.classList.add(mode);
  }

  getCurrentTheme(): ThemeMode {
    const currentTheme = localStorage.getItem(THEME_KEY);

    if (currentTheme === ThemeMode.DARK) {
      return ThemeMode.DARK;
    }

    return ThemeMode.LIGHT;
  }
}
