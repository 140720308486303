import { Component } from '@angular/core';
import { NavigatePipe } from '@ieShared/pipes/navigate.pipe';
import { RouterLink } from '@angular/router';
import { NavService } from '@ieCore/services/nav.service';
import { IeButtonComponent } from '@ieShared/components/ie-button/ie-button.component';

@Component({
  selector: 'app-not-found',
  standalone: true,
  imports: [NavigatePipe, RouterLink, IeButtonComponent],
  templateUrl: './not-found.component.html',
  styleUrl: './not-found.component.scss',
})
export class NotFoundComponent {
  constructor(public navService: NavService) {}
}
