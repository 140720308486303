import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '@ieCore/services/auth.service';
import { UserType } from 'shared-general-libs/type/user';
import { NavService } from '@ieCore/services/nav.service';

export const auditorGuard: CanActivateFn = () => {
  const navService = inject(NavService);
  const router = inject(Router);

  const authService = inject(AuthService);

  if (authService.getUserTypeFromToken() === UserType.AUDITOR_USER) {
    return true;
  }

  router
    .navigate(navService.to.notFound())
    .then(() => console.log(`Navigated to not found page`));

  return false;
};
