@if (mode === 'simple') {
  <div class="title mb-24 pl-16 pr-16">Notification</div>
}
@if (notifications.length) {
  <div class="tabs mb-24 pl-16 pr-16 flex">
    <ie-button
      class="mr-8"
      [buttonType]="status === 'all' ? 'primary' : 'outline'"
      [size]="'sm'"
      (click)="statusChange('all')"
    >
      All
    </ie-button>
    <ie-button
      class="mr-8"
      [buttonType]="status === 'unread' ? 'primary' : 'outline'"
      [size]="'sm'"
      (click)="statusChange('unread')"
    >
      Unread
    </ie-button>
    @if (mode === 'seeAll') {
      <ie-button
        class="ml-auto"
        [buttonType]="'outline'"
        [size]="'md'"
        (click)="seeAll.emit()"
      >
        Mark all as read
      </ie-button>
    }
  </div>
  @if (loading) {
    <div class="notifications-loading flex justify-center">
      <nz-spin></nz-spin>
    </div>
  } @else {
    <div class="notifications-container">
      <div class="flex justify-between mb-8 mt-8 pl-16 pr-16">
        <div>New</div>
        @if (mode === 'simple') {
          <div
            [tabindex]="200"
            class="cursor-pointer"
            (click)="seeAll.emit()"
            (keydown)="seeAll.emit()"
          >
            See all
          </div>
        }
      </div>
      <div class="flex flex-col">
        @for (notification of notifications; track notification.id) {
          <div class="notification-item flex p-16 pr-24">
            <div class="notification-icon mr-16">
              <nz-avatar nzText="AA" nzSize="large"></nz-avatar>
            </div>
            <div class="notification-text mr-16">
              <div class="notification-item-title">
                {{ notification.sender.firstName }}
              </div>
              <div
                class="notification-item-text"
                [innerHTML]="notification.inappMsg"
              ></div>
              <div class="notification-item-date">
                {{ notification.created | timeago }}
              </div>
            </div>
            <div class="notification-icon flex items-center">
              <div
                class="notification-status"
                [ngClass]="{
                  'notification-status--unread':
                    notification.inappStatus === InAppStatus.UNREAD,
                }"
              ></div>
            </div>
          </div>
        }
      </div>

      <div class="flex justify-between mb-8 mt-8 pl-16 pr-16">
        <div>Earlier</div>
      </div>
      <div class="flex flex-col">
        @for (notification of notifications; track notification.id) {
          <div class="notification-item flex p-16 pr-24">
            <div class="notification-icon mr-16">
              <nz-avatar nzText="AA" nzSize="large"></nz-avatar>
            </div>
            <div class="notification-text mr-16">
              <div class="notification-item-title">
                {{ notification.sender.firstName }}
              </div>
              <div
                class="notification-item-text"
                [innerHTML]="notification.inappMsg"
              ></div>
              <div class="notification-item-date">
                {{ notification.created | timeago }}
              </div>
            </div>
            <div class="notification-icon flex items-center">
              <div
                class="notification-status"
                [ngClass]="{
                  'notification-status--unread':
                    notification.inappStatus === InAppStatus.UNREAD,
                }"
              ></div>
            </div>
          </div>
        }
      </div>
      @if (mode === 'seeAll') {
        <ie-button
          class="pl-16 pr-16 mt-24 mb-4"
          buttonType="outline"
          size="sm"
          [fullWidth]="true"
        >
          See Previous Notifications
        </ie-button>
      }
    </div>
  }
} @else {
  <div class="notifications-empty flex flex-col items-center">
    <img
      class="mb-4"
      ngSrc="/assets/icons/notification/empty-notification.svg"
      height="188"
      width="220"
      alt="No notifications"
    />
    <div class="mb-16">Get notified here</div>
    <div class="flex t-align-c">
      This is where you’ll see all your notifications from Requests, reviews and
      more.
    </div>
  </div>
}
