import { reduce, mergeDeepRight } from 'ramda';
import { forkJoin, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
const concatJson = path => path.concat('.json');
const PATH_TEMPLATE_REGEX = /{([^}]+)}/gi;
const PATH_CLEAN_REGEX = /([^:]\/)\/+/gi;
const DEFAULT_PATH_TEMPLATE = '{baseTranslateUrl}/{moduleName}/{language}';
class ModuleTranslateLoader {
  /**
   * The ModuleTranslateLoader for 'ngx-translate/core'
   *
   * @description Fetch multiple translation files (http).
   *
   * @param http the HttpClient from 'angular/common'
   * @param options the configurable options for ModuleTranslateLoader
   *
   * @see https://github.com/larscom/ngx-translate-module-loader
   */
  constructor(http, options) {
    this.http = http;
    this.options = options;
    this.defaultOptions = {
      disableNamespace: false,
      lowercaseNamespace: false,
      deepMerge: true,
      ...this.options
    };
  }
  getTranslation(language) {
    const {
      defaultOptions: options
    } = this;
    return this.mergeTranslations(this.getModuleTranslations(language, options), options);
  }
  mergeTranslations(moduleTranslations, {
    deepMerge,
    translateMerger
  }) {
    return forkJoin(moduleTranslations).pipe(map(translations => {
      return translateMerger ? translateMerger(translations) : deepMerge ? reduce(mergeDeepRight, Object(), translations) : translations.reduce((acc, curr) => ({
        ...acc,
        ...curr
      }), Object());
    }));
  }
  getModuleTranslations(language, options) {
    const {
      modules
    } = options;
    return modules.map(module => {
      const {
        moduleName
      } = module;
      return moduleName ? this.fetchTranslationForModule(language, options, module) : this.fetchTranslation(language, options, module);
    });
  }
  fetchTranslation(language, {
    translateError,
    version,
    headers
  }, {
    pathTemplate,
    baseTranslateUrl,
    translateMap
  }) {
    const pathOptions = Object({
      baseTranslateUrl,
      language
    });
    const template = pathTemplate || DEFAULT_PATH_TEMPLATE;
    const cleanedPath = concatJson(template.replace(PATH_TEMPLATE_REGEX, (_, m1) => pathOptions[m1] || '')).replace(PATH_CLEAN_REGEX, '$1');
    const path = version ? `${cleanedPath}?v=${version}` : cleanedPath;
    return this.http.get(path, {
      headers
    }).pipe(map(translation => translateMap ? translateMap(translation) : translation), this.catchError(cleanedPath, translateError));
  }
  fetchTranslationForModule(language, {
    disableNamespace,
    lowercaseNamespace,
    translateError,
    version,
    headers
  }, {
    pathTemplate,
    baseTranslateUrl,
    moduleName,
    namespace,
    translateMap,
    headers: moduleHeaders
  }) {
    const pathOptions = Object({
      baseTranslateUrl,
      moduleName,
      language
    });
    const template = pathTemplate || DEFAULT_PATH_TEMPLATE;
    const namespaceKey = namespace ? namespace : lowercaseNamespace ? moduleName.toLowerCase() : moduleName.toUpperCase();
    const cleanedPath = concatJson(template.replace(PATH_TEMPLATE_REGEX, (_, m1) => pathOptions[m1] || '')).replace(PATH_CLEAN_REGEX, '$1');
    const path = version ? `${cleanedPath}?v=${version}` : cleanedPath;
    return this.http.get(path, {
      headers: moduleHeaders || headers
    }).pipe(map(translation => {
      return translateMap ? translateMap(translation) : disableNamespace ? translation : Object({
        [namespaceKey]: translation
      });
    }), this.catchError(cleanedPath, translateError));
  }
  catchError(path, translateError) {
    return catchError(e => {
      if (translateError) {
        translateError(e, path);
      }
      console.error('Unable to load translation file:', path);
      return of(Object());
    });
  }
}

/**
 * Generated bundle index. Do not edit.
 */

export { ModuleTranslateLoader };
