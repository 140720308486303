import { HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '@ieCore/services/auth.service';
import {
  isIeCall,
  isRefreshTokenCall,
  setToken,
} from '@ieCore/helpers/interceptor.helper';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  if (skipAuthInterceptor(req)) return next(req);

  const authService = inject(AuthService);
  const authData = authService.getAuthData();

  if (authService.isAuthenticated()) {
    req = setToken(req, authData);
  }

  return next(req);
};

const skipAuthInterceptor = (req: HttpRequest<unknown>) => {
  return !isIeCall(req) || isRefreshTokenCall(req);
};
