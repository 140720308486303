import {
  HttpErrorResponse,
  HttpInterceptorFn,
  HttpStatusCode,
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { inject } from '@angular/core';
import { AuthService } from '@ieCore/services/auth.service';
import { Router } from '@angular/router';
import { NavService } from '@ieCore/services/nav.service';
import { isIeCall } from '@ieCore/helpers/interceptor.helper';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  if (!isIeCall(req)) {
    return next(req);
  }

  const authService = inject(AuthService);
  const navService = inject(NavService);
  const router = inject(Router);

  return next(req).pipe(
    catchError((error) => {
      if (error instanceof HttpErrorResponse) {
        switch (error.status) {
          case HttpStatusCode.Unauthorized: {
            if (authService.isAuthenticated()) {
              authService.removeAuthData();
              router
                .navigate(navService.to.login())
                .then((e) => console.log(e));
            }
            break;
          }
          case HttpStatusCode.PreconditionFailed: {
            if (authService.isAuthenticated()) {
              router
                .navigate(navService.to.forcePasswordReset())
                .then((e) => console.log(e));
            }
            break;
          }
        }
      }
      return throwError(error);
    }),
  );
};
