import { Routes } from '@angular/router';
import { guestGuard } from '@ieCore/guards/guest.guard';
import { authGuard } from '@ieCore/guards/auth.guard';
import { partnerGuard } from '@ieCore/guards/partner.guard';
import { auditorGuard } from '@ieCore/guards/auditor.guard';
import { certifierGuard } from '@ieCore/guards/certifier.guard';
import { enterpriseGuard } from '@ieCore/guards/enterprise.guard';
import { adminGuard } from '@ieCore/guards/admin.guard';
import { portfolioGuard } from '@ieCore/guards/portfolio.guard';

import { NotFoundComponent } from '@ieApp/modules/misc/not-found/not-found.component';
import { LayoutComponent } from '@ieShared/components/layout/layout.component';

export const routes: Routes = [
  {
    path: '',
    canActivate: [guestGuard],
    data: {
      title: 'PAGE_TITLE.DEFAULT',
    },
    loadChildren: () =>
      import('./modules/guest/guest.routes').then((m) => m.guestRoutes),
  },
  {
    path: 'admin',
    component: LayoutComponent,
    canActivate: [authGuard, adminGuard],
    data: {
      title: 'PAGE_TITLE.DASHBOARD',
    },
    loadChildren: () =>
      import('./modules/admin/admin.routes').then((m) => m.adminRoutes),
  },
  {
    path: 'partner',
    component: LayoutComponent,
    canActivate: [authGuard, partnerGuard],
    data: {
      title: 'PAGE_TITLE.DASHBOARD',
    },
    loadChildren: () =>
      import('./modules/partner/partner.routes').then((m) => m.partnerRoutes),
  },
  {
    path: 'portfolio',
    component: LayoutComponent,
    canActivate: [authGuard, portfolioGuard],
    data: {
      title: 'PAGE_TITLE.DASHBOARD',
    },
    loadChildren: () =>
      import('./modules/portfolio/portfolio.routes').then(
        (m) => m.portfolioRoutes,
      ),
  },
  {
    path: 'auditor',
    component: LayoutComponent,
    canActivate: [authGuard, auditorGuard],
    data: {
      title: 'PAGE_TITLE.DASHBOARD',
    },
    loadChildren: () =>
      import('./modules/auditor/auditor.routes').then((m) => m.auditorRoutes),
  },
  {
    path: 'certifier',
    component: LayoutComponent,
    canActivate: [authGuard, certifierGuard],
    data: {
      title: 'PAGE_TITLE.DASHBOARD',
    },
    loadChildren: () =>
      import('./modules/certifier/certifier.routes').then(
        (m) => m.certifierRoutes,
      ),
  },
  {
    path: 'enterprise',
    component: LayoutComponent,
    canActivate: [authGuard, enterpriseGuard],
    data: {
      title: 'PAGE_TITLE.DASHBOARD',
    },
    loadChildren: () =>
      import('./modules/enterprise/enterprise.routes').then(
        (m) => m.enterpriseRoutes,
      ),
  },
  {
    path: 'not-found',
    loadComponent: () => NotFoundComponent,
  },
  {
    path: '**',
    loadComponent: () => NotFoundComponent,
  },
];
