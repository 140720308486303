import { IModuleTranslationOptions } from '@larscom/ngx-translate-module-loader';
import { cashVersion } from '@ieRoot/version-manager/version-manager';

export const DEFAULT_LANGUAGE = 'en';
export const BASE_TRANSLATE_URL = './assets/i18n';

const baseTranslateUrl = BASE_TRANSLATE_URL;

export const TRANSLATION_MODULES: IModuleTranslationOptions = {
  version: cashVersion,
  modules: [
    { baseTranslateUrl },
    { baseTranslateUrl, moduleName: 'nav_menu' },
    { baseTranslateUrl, moduleName: 'page_title' },
    { baseTranslateUrl, moduleName: 'login' },
    { baseTranslateUrl, moduleName: 'forgot_password' },
    { baseTranslateUrl, moduleName: 'topic' },
    { baseTranslateUrl, moduleName: 'emission_factor' },
    { baseTranslateUrl, moduleName: 'role' },
    { baseTranslateUrl, moduleName: 'equipment' },
    { baseTranslateUrl, moduleName: 'form_validation' },
    { baseTranslateUrl, moduleName: 'password_strength' },
    { baseTranslateUrl, moduleName: 'partner' },
    { baseTranslateUrl, moduleName: 'collect' },
    { baseTranslateUrl, moduleName: 'status' },
    { baseTranslateUrl, moduleName: 'notification' },
    { baseTranslateUrl, moduleName: 'category_tree' },
    { baseTranslateUrl, moduleName: 'user' },
    { baseTranslateUrl, moduleName: 'company' },
    { baseTranslateUrl, moduleName: 'permissions' },
    { baseTranslateUrl, moduleName: 'facilities' },
    { baseTranslateUrl, moduleName: 'header' },
    { baseTranslateUrl, moduleName: 'task' },
    { baseTranslateUrl, moduleName: 'review' },
    { baseTranslateUrl, moduleName: 'energy_emission_factor' },
    { baseTranslateUrl, moduleName: 'accounting' },
    { baseTranslateUrl, moduleName: 'block_chain' },
    { baseTranslateUrl, moduleName: 'note' },
    { baseTranslateUrl, moduleName: 'org' },
    { baseTranslateUrl, moduleName: 'data_request' },
    { baseTranslateUrl, moduleName: 'units_be' },
    // Topics
    { baseTranslateUrl, moduleName: 'topic/general_disclosure', namespace: 'TOPICS:GENERAL_DISCLOSURE' },
    { baseTranslateUrl, moduleName: 'topic/materials', namespace: 'TOPICS:MATERIALS' },
    { baseTranslateUrl, moduleName: 'topic/water_and_effluents', namespace: 'TOPICS:WATER_AND_EFFLUENTS' },
    {
      baseTranslateUrl,
      moduleName: 'topic/employment',
      namespace: 'TOPICS:EMPLOYMENT',
    },
  ],
};
