<div class="lt-container flex">
  <div class="lt-nav flex flex-col" [ngClass]="{ closed: closed }">
    <div class="logo flex mr-16 ml-16 mt-24">
      @if (closed) {
        <img ngSrc="assets/logo.svg" alt="logo" width="51" height="32" />
      } @else {
        <img ngSrc="assets/logo-text.svg" alt="logo" width="238" height="32" />
      }
    </div>

    <nz-divider class="ml-16 mr-16"></nz-divider>

    <app-nav-bar [items]="menuConfig" [small]="closed"></app-nav-bar>

    <nz-divider class="ml-16 mr-16"></nz-divider>

    <button
      class="lt-nav-controller flex items-center cursor-pointer justify-center"
      (click)="toggle()"
    >
      <ie-icons
        class="mr-16"
        [ngClass]="{ 'mr-16': !closed }"
        [width]="20"
        [height]="20"
        [icon]="closed ? 'side-expand' : 'side-collapse'"
      ></ie-icons>
    </button>
  </div>

  <div class="lt-content">
    <app-header class="mb-16"></app-header>
    <router-outlet />
  </div>
</div>
