import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { cashVersion } from '@ieRoot/version-manager/version-manager';
import { environment } from '@ieEnv/environment';

@Injectable({
  providedIn: 'root',
})
export class SvgIconsService {
  version = cashVersion;

  constructor(private http: HttpClient) {
    if (!environment.production) {
      this.version = this.version + this.getRandomNumber(1, 1000);
    }
  }

  loadSvgSprite(url: string): void {
    this.http
      .get(url + '?version=' + this.version, { responseType: 'text' })
      .subscribe((svgContent) => {
        const div = document.createElement('div');
        div.style.display = 'none';
        div.innerHTML = svgContent;
        document.body.insertBefore(div, document.body.firstChild);
      });
  }

  getRandomNumber(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}
