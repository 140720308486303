import { HttpRequest } from '@angular/common/http';
import { AuthResponseDto } from 'shared-general-libs/dto/auth';

export function isIeCall(req: HttpRequest<unknown>): boolean {
  return req.url.includes('/api');
}

export function setToken(req: HttpRequest<unknown>, authData: AuthResponseDto) {
  return req.clone({
    setHeaders: {
      Authorization: `Bearer ${authData.accessToken}`,
    },
  });
}

export function isRefreshTokenCall(req: HttpRequest<unknown>): boolean {
  return req.headers.has('refresh-token');
}
