import { Component, Input, OnInit } from '@angular/core';
import { IeIconsComponent } from '@ieShared/components/ie-icons/ie-icons.component';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NavigatePipe } from '@ieShared/pipes/navigate.pipe';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzButtonComponent } from 'ng-zorro-antd/button';
import { NavItem } from '@ieShared/interfaces/nav-bar.interface';
import { deepCopy } from 'shared-general-libs/utils';
import { UserService } from '@ieCore/services/user.service';
import { HasPermissionDirective } from '@ieShared/directives/has-permission.directive';

@Component({
  selector: 'app-nav-bar',
  standalone: true,
  imports: [
    IeIconsComponent,
    RouterLinkActive,
    NgClass,
    RouterLink,
    TranslateModule,
    NavigatePipe,
    NzPopoverModule,
    NzButtonComponent,
    HasPermissionDirective,
  ],
  providers: [NavigatePipe],
  templateUrl: './nav-bar.component.html',
  styleUrl: './nav-bar.component.scss',
})
export class NavBarComponent implements OnInit {
  @Input() items: NavItem[] = [];

  @Input() small = false;

  filteredItems!: NavItem[];

  constructor(
    private router: Router,
    private navigatePipe: NavigatePipe,
    private userService: UserService,
  ) {}

  async ngOnInit() {
    this.filteredItems = await this.filterNavByPermission(deepCopy(this.items));
    this.checkForActive(this.items);
  }

  async filterNavByPermission(items: NavItem[]) {
    const filteredMenu: NavItem[] = [];

    for (const menuItem of items) {
      if (menuItem.children) {
        const filteredChildren = [];
        for (const child of menuItem.children) {
          if (await this.userService.hasPermission(child.permission, true)) {
            filteredChildren.push(child);
          }
        }
        menuItem.children = filteredChildren;
      }

      if (
        !menuItem.children &&
        (await this.userService.hasPermission(menuItem.permission, true))
      ) {
        filteredMenu.push(menuItem);
      }

      if (
        menuItem.children &&
        menuItem.children.length &&
        (await this.userService.hasPermission(menuItem.permission, true))
      ) {
        filteredMenu.push(menuItem);
      }
    }

    return filteredMenu;
  }

  checkForActive(items: NavItem[]) {
    items.forEach((item) => {
      if (item.children) {
        item.children.forEach((child) => {
          if (this.router.url === this.navigatePipe.transform(child.route)) {
            item.opened = true;
          }
        });
      }
    });
  }

  toggleMenu(item: NavItem) {
    if (item.route) {
      return;
    }

    item.opened = !item.opened;
  }
}
